import { Routes } from "@angular/router";

import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { AuthGuard } from "./services/authentication/auth-guard";

export const routes: Routes = [
  // Pages component show the sidenav and toolbars
  {
    path: "",
    component: PagesComponent,
    children: [
      // ---------------------------------------------
      //     PUBLIC ROUTES
      // ---------------------------------------------
      {
        path: "",
        data: { canonical: "" },
        loadComponent: () =>
          import("./pages/home/home.component").then((c) => c.HomeComponent),
      },
      {
        path: "contact/:reason",
        data: { canonical: "/contact" },
        loadComponent: () =>
          import("./pages/contact/contact.component").then(
            (c) => c.ContactComponent
          ),
      },
      {
        path: "contact",
        data: { canonical: "/contact" },
        loadComponent: () =>
          import("./pages/contact/contact.component").then(
            (c) => c.ContactComponent
          ),
      },
      {
        path: "consulting",
        data: { canonical: "/consulting" },
        loadComponent: () =>
          import("./pages/consulting/consulting.component").then(
            (c) => c.ConsultingComponent
          ),
      },
      {
        path: "properties/sale",
        data: { canonical: "/properties/sale" },
        loadComponent: () =>
          import("./pages/properties/properties.component").then(
            (c) => c.PropertiesComponent
          ),
      },
      {
        path: "properties/rent",
        data: { canonical: "/properties/rent" },
        loadComponent: () =>
          import("./pages/properties/properties.component").then(
            (c) => c.PropertiesComponent
          ),
      },
      {
        path: "properties/:id",
        loadComponent: () =>
          import("./pages/property/property.component").then(
            (c) => c.PropertyComponent
          ),
      },
      {
        path: "ref/:id",
        loadComponent: () =>
          import("./pages/referals/referals.component").then(
            (c) => c.ReferalsComponent
          ),
      },
      {
        path: "signup",
        loadComponent: () =>
          import("./pages/referals/referals.component").then(
            (c) => c.ReferalsComponent
          ),
      },
      { path: "properties", redirectTo: "properties/sale" },
      {
        path: "pricing/:status",
        data: { canonical: "/pricing" },
        loadComponent: () =>
          import("./pages/pricing/pricing.component").then(
            (mod) => mod.PricingComponent
          ),
      },
      {
        path: "pricing",
        data: { canonical: "/pricing" },
        loadComponent: () =>
          import("./pages/pricing/pricing.component").then(
            (mod) => mod.PricingComponent
          ),
      },
      {
        path: "terms-conditions",
        data: { canonical: "/terms-conditions" },
        loadComponent: () =>
          import("./pages/terms-conditions/terms-conditions.component").then(
            (m) => m.TermsConditionsComponent
          ),
      },
      {
        path: "privacy-policy",
        data: { canonical: "/privacy-policy" },
        loadComponent: () =>
          import("./pages/privacy-policy/privacy-policy.component").then(
            (c) => c.PrivacyPolicyComponent
          ),
      },
      {
        path: "analysis/:propertyId",
        data: { canonical: "/analysis" },
        loadComponent: () =>
          import("./pages/analysis/analysis.component").then(
            (c) => c.AnalysisComponent
          ),
      },
      {
        path: "analysis",
        data: { canonical: "/analysis" },
        loadComponent: () =>
          import("./pages/analysis/analysis.component").then(
            (c) => c.AnalysisComponent
          ),
      },
      // List of Blogs
      {
        path: "blogs",
        data: { canonical: "/blogs" },
        loadComponent: () =>
          import("./pages/blogs/blogs.component").then(
            (com) => com.BlogsComponent
          ),
      },
      {
        path: "blogs/step-by-step-buyer",
        data: { canonical: "/blogs/step-by-step-buyer" },
        loadComponent: () =>
          import(
            "./pages/blogs/articles/buyer/step-by-step/step-by-step.component"
          ).then((com) => com.StepByStepBuyerComponent),
      },
      {
        path: "blogs/step-by-step-seller",
        data: { canonical: "/blogs/step-by-step-seller" },
        loadComponent: () =>
          import(
            "./pages/blogs/articles/seller/step-by-step/step-by-step.component"
          ).then((com) => com.StepByStepSellerComponent),
      },
      {
        path: "blogs/step-by-step-tenant",
        data: { canonical: "/blogs/step-by-step-tenant" },
        loadComponent: () =>
          import(
            "./pages/blogs/articles/tenant/step-by-step/step-by-steps.component"
          ).then((com) => com.StepByStepTenantComponent),
      },
      {
        path: "blogs/step-by-step-landlord",
        data: { canonical: "/blogs/step-by-step-landlord" },
        loadComponent: () =>
          import(
            "./pages/blogs/articles/landlord/step-by-step/step-by-step.component"
          ).then((com) => com.StepByStepLandlordComponent),
      },
      {
        path: "blogs/surveys/:survey",
        loadComponent: () =>
          import("./pages/blogs/survey/survey.component").then(
            (m) => m.SurveyComponent
          ),
      },
      {
        path: "blogs/should-you-use-an-agent",
        data: { canonical: "/blogs/should-you-use-an-agent" },
        loadComponent: () =>
          import(
            "./pages/blogs/articles/seller/real-estate-agent/real-estate-agent.component"
          ).then((m) => m.RealEstateAgentComponent),
      },
      {
        path: "blogs/exclusive-vs-non-exclusive-mandates",
        data: { canonical: "/blogs/exclusive-vs-non-exclusive-mandates" },
        loadComponent: () =>
          import(
            "./pages/blogs/articles/seller/mandat-exclusif-or-simple/mandat-exclusif-or-simple.component"
          ).then((m) => m.MandatExclusifOrSimpleComponent),
      },

      // ---------------------------------------------
      //     USER PROTECTED ROUTES
      // ---------------------------------------------
      {
        path: "settings",
        loadComponent: () =>
          import("./pages/settings/settings.component").then(
            (c) => c.SettingsComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "submit-property",
        loadComponent: () =>
          import("./pages/submit-property/submit-property.component").then(
            (com) => com.SubmitPropertyComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "property-settings/:id",
        loadComponent: () =>
          import("./pages/submit-property/submit-property.component").then(
            (c) => c.SubmitPropertyComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "my-properties",
        loadComponent: () =>
          import("./pages/my-properties/my-properties.component").then(
            (c) => c.MyPropertiesComponent
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "favorites",
        loadComponent: () =>
          import("./pages/favorites/favorites.component").then(
            (m) => m.FavoritesComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "chat",
        loadChildren: () =>
          import("./pages/chat/chat.module").then((m) => m.ChatModule),
        canActivate: [AuthGuard],
      },
      {
        path: "chat2",
        loadComponent: () =>
          import("./pages/chat-2/chat-2.component").then(
            (com) => com.Chat2Component
          ),
        canActivate: [AuthGuard],
      },
      // ---------------------------------------------
      //     ADMIN PROTECTED ROUTES
      // ---------------------------------------------
      {
        path: "admin",
        loadComponent: () =>
          import("./pages/admin/admin.component").then(
            (com) => com.AdminComponent
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  { path: "**", component: NotFoundComponent },
];
